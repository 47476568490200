exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-about-tsx": () => import("./../../../src/pages/lab/about.tsx" /* webpackChunkName: "component---src-pages-lab-about-tsx" */),
  "component---src-pages-lab-index-tsx": () => import("./../../../src/pages/lab/index.tsx" /* webpackChunkName: "component---src-pages-lab-index-tsx" */),
  "component---src-pages-lab-work-tsx": () => import("./../../../src/pages/lab/work.tsx" /* webpackChunkName: "component---src-pages-lab-work-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-lab-work-detail-tsx": () => import("./../../../src/templates/lab-work-detail.tsx" /* webpackChunkName: "component---src-templates-lab-work-detail-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/news-detail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */),
  "component---src-templates-work-detail-tsx": () => import("./../../../src/templates/work-detail.tsx" /* webpackChunkName: "component---src-templates-work-detail-tsx" */),
  "component---src-templates-workdetail-detail-tsx": () => import("./../../../src/templates/workdetail-detail.tsx" /* webpackChunkName: "component---src-templates-workdetail-detail-tsx" */)
}

